
export interface AuthProviderUserInfo {
    userId: string;
    userName: string;
    jwtAccessToken: string;
    jwtIdToken: string;
    email?: string;
}

// Create the context with typed state
export interface AuthInfo {
    providerInfo: AuthProviderUserInfo;
}

export class NotLoggedInError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "NotLoggedInError";
    }
}

export class BadRequestError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "BadRequestError";
    }
}
const callBackend = async (authInfo: AuthInfo | undefined, url_path: string, method: string, body?: any, must_be_auth?: boolean): Promise<Response> => {
    must_be_auth = must_be_auth === undefined ? url_path.startsWith('secure/') : must_be_auth;
    if (must_be_auth && !authInfo) {
        throw new NotLoggedInError("Authorization information is required.");
    }
    const headersMap: { [key: string]: string } = {
        "Content-Type": "application/json"
    };
    
    if (authInfo && authInfo.providerInfo) {
        headersMap["Authorization"] = `Bearer ${authInfo.providerInfo.jwtAccessToken}`;
        headersMap["X-Cognito-Id-Token"] = `${authInfo.providerInfo.jwtIdToken}`;
    }
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${url_path}`,
        {
            method,
            headers: headersMap,
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify(body),
        },
    );
    if (response.status === 401) {
        throw new NotLoggedInError("Authorization information is required.");
    }
    if (response.status === 400) {
        throw new BadRequestError((await response.json()).detail);
    }
    if (response.status !== 200) {
        console.error('Response status:', response.status);
        console.error('Response text:', await response.text());
        throw new Error('Unexpected status code: ' + response.status);
    }

    return response;
};

export const call_backend = async (authInfo: AuthInfo| undefined, url_path: string, must_be_auth?: boolean): Promise<Response> => {
    return callBackend(authInfo, url_path, "GET", undefined, must_be_auth);
};

export const call_backend_post = async (authInfo: AuthInfo| undefined, url_path: string, body: any, must_be_auth?: boolean): Promise<Response> => {
    return callBackend(authInfo, url_path, "POST", body, must_be_auth);
}; 