import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { PluginComponent } from 'react-markdown-editor-lite';

interface FullScreenState {
    enable: boolean;
}


export default class CustomFullScreen extends PluginComponent<FullScreenState> {
    static pluginName = 'custom-full-screen';

    static align = 'right';
    doNothing = (_enable: boolean) => { };
    onFullScreenChange: ((enable: boolean) => void) = this.getConfig('onFullScreenChange') || this.doNothing;

    constructor(props: any) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            enable: this.editor.isFullScreen(),
        };
    }

    private handleClick() {
        this.editor.fullScreen(!this.state.enable);
    }

    private handleChange(enable: boolean) {
        this.setState({ enable });
        if (this.getConfig('changeMenuVisibility')) {
            this.editor.setView({ menu: enable });
        }
        this.onFullScreenChange(enable);
    }

    componentDidMount() {
        this.editor.on('fullscreen', this.handleChange);
    }

    componentWillUnmount() {
        this.editor.off('fullscreen', this.handleChange);
    }

    render() {
        if (this.editorConfig.canView && this.editorConfig.canView.fullScreen) {
            const { enable } = this.state;
            return (
                <span
                    className="button button-type-fullscreen"
                    title={enable ? 'Exit full screen' : 'Enter full screen'}
                    onClick={this.handleClick}
                >
                    {enable ? <FullscreenExit /> : <Fullscreen />}
                </span>
            );
        }
        return null;
    }
}