import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { sideBarItems, sideBarItemsWhenOpenOnly } from "./AppSideBar";

interface AppBottomMenuProps {
  visible: boolean;
}

export function AppBottomMenu(props: AppBottomMenuProps) {

  return (
    props.visible 
    ? <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} >
      <BottomNavigation
        showLabels
        value={1}
        onChange={(event, newValue) => {

        }}
      >
        {[...sideBarItems, ...sideBarItemsWhenOpenOnly].map((item, _index) => (
          <BottomNavigationAction key={item.title} label={item.title} icon={item.icon} href={item.href} />
        ))}
      </BottomNavigation>
    </Paper>
    : <></>
  );
}
