import {
  Avatar,
  Box,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import React from "react";
import { getGravatarUrl } from "../common/gravatar";
import { loadUserDetails } from "../common/loaders/userDetails";
import { AuthInfo } from "../hocComponents/Backend";
import { withAuth } from "../hocComponents/withAuth";
import { withLoaders } from "../hocComponents/withLoaders";

type Props = {
  authInfo: AuthInfo;
  reload: () => void;
  loaderResults: { [key: string]: any };
};

const UserProfilePage: React.FC<Props> = ({ authInfo, loaderResults }) => {

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Box style={{ padding: 20, marginTop: 20, }} minWidth={500}>
          <div>
            {loaderResults.userDetails ? (
              <>
                {/* Basic Information Paper */}
                <Paper style={{ padding: 20, marginTop: 20 }}>
                  <Typography variant="h4" sx={{ ml: 5 }}>
                    Your Profile
                  </Typography>
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" sx={{ fontWeight: "bold", ml: 5 }}>
                          Profile Photo:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontSize: 11 }}>
                          <a href="https://gravatar.com/" target="_blank" rel="noopener noreferrer"> Edit your avatar at https://gravatar.com/</a>
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ position: "relative" }} justifyContent="flex-end">
                        <Avatar alt="Edit your gravatar at https://gravatar.com/" src={getGravatarUrl(loaderResults.userDetails.email || "")} />
                      </Grid>
                    </Grid>
                    {/* User Name */}
                    {/* <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography variant="subtitle2" sx={{ fontWeight: "bold", ml: 5 }}>
                          User Name:
                        </Typography>
                      </Grid>
                      <Grid item xs={8} justifyContent="flex-end">
                        <Typography variant="body1" justifyContent="center">
                          {authInfo?.providerInfo?.userName}
                        </Typography>
                      </Grid>
                    </Grid> */}
                    {/* E-mail */}
                    <Grid container alignItems="center">
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold", ml: 5 }}
                        >
                          E-mail:
                        </Typography>
                      </Grid>
                      <Grid item xs={8} justifyContent="flex-end">
                        <Typography variant="body1">
                          {loaderResults.userDetails.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </>
            ) : (
              <Box>
                <Typography variant="h4">User Profile</Typography>
                <Typography variant="body1">
                  Error fetching user profile.
                </Typography>
              </Box>
            )}
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withAuth(withLoaders(UserProfilePage, { userDetails: loadUserDetails }));

