import { AuthInfo } from "../../hocComponents/Backend";

export async function loadCategories(authInfo: AuthInfo | undefined): Promise<string[]> {
    return [
        'Finance',
        'Software Engineering',
        'Management',
        'Law',
        'Philosophy',
        'Technology',
        'Health',
        'Science',
        'Education',
        'Art',
        'Music',
        'Sports',
        'Food',
        'Travel',
        'Fashion',
        'Fitness',
        'Business',
        'Marketing',
        'Politics',
        'History',
        'Mathematics',
        'Psychology',
        'Environment',
        'Literature',
        'Film',
        'Gaming',
        'Design',
        'Photography',
        'Architecture',
        'Cuisine',
        'Nature',
        'Astronomy',
        'Anthropology',
        'Biology',
        'Chemistry',
        'Physics',
        'Geography',
        'Geology',
        'Sociology',
        'Economics',
        'Religion',
        'Spirituality',
        'Ethics',
        'Linguistics',
        'Journalism',
        'Dance',
        'Theatre',
        'Robotics',
        'Artificial Intelligence',
        'Machine Learning',
        'Cybersecurity',
        'Networking',
        'Data Science',
        'Blockchain',
        'Cryptocurrency',
        'Virtual Reality',
        'Augmented Reality',
        '3D Printing',
        'Nanotechnology',
        'Biotechnology',
        'Medicine',
        'Nursing',
        'Dentistry',
        'Pharmacy',
        'Veterinary',
        'Public Health',
        'Nutrition',
        'Yoga',
        'Meditation',
        'Personal Development',
        'Entrepreneurship',
        'Leadership',
        'Human Resources',
        'Real Estate',
        'Investing',
        'Stock Market',
        'Insurance',
        'Taxation',
        'Advertising',
        'Public Relations',
        'Sales',
        'E-commerce',
        'Customer Service',
        'Non-Profit',
        'Social Work',
        'Urban Planning',
        'Public Administration',
        'International Relations',
        'Civil Engineering',
        'Mechanical Engineering',
        'Electrical Engineering',
        'Chemical Engineering',
        'Agriculture',
        'Forestry',
        'Horticulture',
        'Marine Biology',
        'Oceanography',
        'Meteorology',
        'Sustainable Development',
        'Renewable Energy',
        'Conservation',
        'Wildlife',
        'Paleontology',
        'Archeology',
        'Genealogy',
        'Mythology',
        'Astrology',
        'Tarot',
        'Numerology',
        // Add more categories here...
        'Other'
    ];
};
