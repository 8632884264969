import { CloseFullscreen, Fullscreen, FullscreenOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, ButtonBase, Dialog, Grid, Paper, Typography } from '@mui/material';
import { MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getGravatarUrl } from '../common/gravatar';
import { Post, loadPostDetails, loadRelatedPosts } from '../common/loaders/postLoaders';
import { isSubscribed, numSubscribers } from '../common/loaders/subscriptions';
import { extractTitle } from '../common/markdownManipulation';
import { isMobileViewport } from '../common/mobileUtils';
import LikeDislikeButtons from '../components/LikeDislikeButtons';
import PostCardBox from '../components/PostCardBox';
import ShareButton from '../components/ShareButton';
import SubscribeButton from '../components/SubscribeButton';
import CommentSection from '../components/comments/CommentSection';
import { AuthInfo } from '../hocComponents/Backend';
import { Loader, withLoaders } from '../hocComponents/withLoaders';
import { withOptionalAuth } from '../hocComponents/withOptionalAuth';

type Props = {
    authInfo: AuthInfo | undefined,
    reload: () => void;
    loaderResults: { [key: string]: any };
};


const ViewPostPage: React.FC<Props> = ({ authInfo, loaderResults, }) => {
    const [isMobileMode, setIsMobileMode] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileMode(isMobileViewport());
        };

        handleResize(); // Set initial value
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const { postId } = useParams<{ postId: string }>();
    const toggleFullScreen = () => {
        console.log('Toggling full screen');
        setIsFullScreen((_prev) => true);
    }


    if (!postId) return (<div>Invalid post id</div>);

    const previewComponent = <MdPreview
        modelValue={loaderResults.postDetails?.post.content}
        language="en-US"
        showCodeRowNumber={true}

    />;

    const shareButton = <ShareButton />;
    const subscribeButton = <SubscribeButton authInfo={authInfo} feedName={loaderResults.postDetails?.post.feed_name} />;
    const likeDislikeButtons = <LikeDislikeButtons authInfo={authInfo} postId={postId} />;

    //463px
    return (
        <Box id="main_box_view_post" display="flex" flexWrap="wrap" justifyContent="center" flexDirection="row" padding={0}  >
            <Dialog
                fullScreen
                open={isFullScreen}
                onClose={() => setIsFullScreen(false)}
            >
                <Box display="flex" width="100%" justifyContent="flex-end">
                    <Button variant='text' onClick={() => setIsFullScreen(false)} ><CloseFullscreen /></Button>
                </Box>
                {previewComponent}
            </Dialog>
            <Box id="article-and-comment-box" display="flex" flexWrap="wrap" alignContent="flex-start" padding={1} flexGrow={1} flexBasis="66vw" >
                <Box width="100%" maxHeight={isMobileMode ? "auto" : "60vh"} overflow="auto" >
                    {previewComponent}
                </Box>

                {!isMobileMode &&
                    <Box id="article-toolbar2-box-desktop" width="100%" padding={0} display="flex" flexWrap="wrap">
                        <Box id="article-toolbar-box-desktop" width="100%" >
                            <Grid container spacing={0} margin={0} >
                                <Grid item width="100%">
                                    <hr />
                                    <Typography gutterBottom variant="h6" component="div" margin={0}>
                                        {extractTitle(loaderResults.postDetails?.post.content)}
                                    </Typography>
                                </Grid>

                                <Grid item xs />
                                {isMobileMode && (
                                    <Grid item>
                                        <Button variant='text' onClick={toggleFullScreen}><Fullscreen /></Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item>
                                <ButtonBase sx={{ width: 40, height: 40 }}>
                                    <Avatar src={getGravatarUrl(loaderResults.postDetails?.post.user_email || "")} sx={{ width: "100%", height: "100%", mr: 2, ml: 2 }} />
                                </ButtonBase>
                            </Grid>
                            <Grid item  >
                                <Grid item xs container direction="column" >
                                    <Grid item xs>
                                        <Typography gutterBottom variant="subtitle2" component="div" margin={0}>
                                            <Link to={`/feed/${loaderResults.postDetails?.post.feed_name}`}>@{loaderResults.postDetails?.post.feed_name}</Link>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" margin={0}>
                                            {loaderResults.postDetails?.numSubscribers} subscribers
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item >
                                {subscribeButton}
                            </Grid>
                            <Grid item xs sm container >
                                <Grid item xs>
                                </Grid>
                                <Grid item>
                                    {likeDislikeButtons}
                                </Grid>
                                <Grid item xs />
                                <Grid item>
                                    {shareButton}
                                </Grid>
                                <Grid item xs />
                                <Grid item>
                                    <Button variant='text' onClick={toggleFullScreen}><Fullscreen sx={{ mr: 1 }} />Fullscreen</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Box id="comments-box" width="100%" mt={2}>
                    <hr />
                    <CommentSection postId={postId} authInfo={authInfo}></CommentSection>
                </Box>
            </Box>
            <Box id="related-box-container" flexGrow={1} flexBasis="25vw" mb="250px">
                <Box>
                    <h3>Related</h3>
                </Box>
                <PostCardBox posts={loaderResults.relatedPosts} authInfo={authInfo} align="center"/> 
            </Box>
            {isMobileMode &&
                <Box id="article-toolbar2-box-mobile" width="100%" padding={0} display="flex" flexWrap="wrap">
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10, p: 0.5 }} elevation={3} >
                        <Box id="article-toolbar-box" width="100%" p={0.5}>
                            <Grid container spacing={0} margin={0}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h6" component="div" margin={0}>
                                        {extractTitle(loaderResults.postDetails?.post.content)}
                                    </Typography>
                                </Grid>

                                <Grid item xs />
                                <Grid item  >
                                    <Button variant='outlined' onClick={toggleFullScreen} ><FullscreenOutlined /></Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display="inline-flex" flexWrap="wrap" justifyContent='space-between' p={0.5}>
                            <ButtonBase sx={{ width: 32, height: 32, mr: 2 }}>
                                <Avatar src={getGravatarUrl(loaderResults.postDetails?.post.user_email || "")} sx={{ width: "100%", height: "100%", }} />
                            </ButtonBase>
                            <Box>
                                <Typography gutterBottom variant="subtitle2" component="div" margin={0} >
                                    {`@${loaderResults.postDetails?.post.feed_name}`}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" margin={0}>
                                    {loaderResults.postDetails?.numSubscribers} subscribers
                                </Typography>
                            </Box>
                            <Box flexGrow={1} />
                            {subscribeButton}
                            <Box width="100%" />
                            {likeDislikeButtons}
                            {shareButton}
                        </Box>
                    </Paper>
                </Box>
            }
        </Box >
    );
};



const ViewPostPageWithLoaders: React.FC<Omit<Props, 'authInfo' | 'reload' | 'loaderResults'>> = (props) => {
    const { postId } = useParams<{ postId: string }>();
    if (!postId) {
        return <div>Post id not provided</div>;
    }
    const loadPostDetailsP: Loader = async (authInfo: AuthInfo | undefined) => {
        let postDetails: Post = await loadPostDetails(authInfo, postId);
        let feed_name = postDetails.feed_name;

        return {
            post: postDetails,
            isSubscribed: await isSubscribed(authInfo, feed_name),
            numSubscribers: await numSubscribers(authInfo, feed_name),
        }
    };
    const loadRelatedPostsP: Loader = (authInfo: AuthInfo | undefined) => loadRelatedPosts(authInfo, postId);

    const FeedPageComponent = withOptionalAuth(withLoaders(ViewPostPage, {
        postDetails: loadPostDetailsP,
        relatedPosts: loadRelatedPostsP,
    }));

    return <FeedPageComponent {...props} />;
}

export default ViewPostPageWithLoaders; 