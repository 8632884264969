import { Avatar, Box, Button, Card, CardActionArea, CardContent, CardMedia, Link, Typography } from '@mui/material';
import React from 'react';
import { getTimeAgo } from '../common/getTimeAgo';
import { getGravatarUrl } from '../common/gravatar';
import { Post } from '../common/loaders/postLoaders';
import { extractPreview, extractTitle } from '../common/markdownManipulation';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AuthInfo } from '../hocComponents/Backend';
const DefaultPostImg = '/example_post.png';
interface Props {
    authInfo: AuthInfo | undefined;
    post: Post;
    width: number | string;
    margin: number | string;
}

const PostCard: React.FC<Props> = ({ post, authInfo, width, margin }) => {
    const navigate = useNavigate();
    const timeAgo = getTimeAgo(post.updated_at);
    const isOwner = post.user_email === authInfo?.providerInfo.email;
    const postLinkUrl = `/post/${post.feed_name}/${post.id}`;
    const handleEdit = () => {
        navigate(`/edit-post/${post.feed_name}/${post.id}`);
    }
    return (
        <Card sx={{ width: width, margin: margin, border: 0, position: 'relative' }} >
            {isOwner && (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ position: 'absolute', top: 2, right: 2, zIndex: 1 }}
                    onClick={handleEdit}
                >
                    Edit
                </Button>
            )}
            <CardActionArea href={postLinkUrl}  >
                <CardMedia
                    component="img"
                    src={DefaultPostImg}
                    alt="Post Image"
                    sx={{ maxHeight: 180 }}
                />
            </CardActionArea>
            <CardContent sx={{ position: 'relative' }}>
                <CardActionArea href={postLinkUrl}  >
                    <Box sx={{ display: 'flex' }}>
                        <Avatar src={getGravatarUrl(post.user_email || '')} sx={{ width: 32, height: 32, mr: 2 }} />
                        <Typography variant="h6" component="div">
                            {extractTitle(post.content || '')}
                        </Typography>
                    </Box>
                    <Typography variant="body2" color="text.subtitle1">
                        {extractPreview(post.content || '', 100)}
                    </Typography>
                </CardActionArea>
                <Typography variant="body2" color="text.secondary">
                    <Link component={RouterLink} to={`/feed/${post.feed_name}`}>
                        {"@"}{post.feed_name}
                    </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {/* 0 likes -  */}
                    {timeAgo}
                </Typography>
            </CardContent>
        </Card >
    );
};

export default PostCard;