
import markdownit from 'markdown-it';

export function extractTitle(markdownText: string): string {
    const mdParser = new markdownit();
    const tokens = mdParser.parse(markdownText, {});
    if (tokens.length > 0 && tokens[0].type === 'heading_open' && tokens[0].tag === 'h1') {
        return tokens[1].content;
    }
    return '';
}

export function extractContent(markdownText: string): string {
    const mdParser = new markdownit();
    const tokens = mdParser.parse(markdownText, {});
    let content = '';
    let foundTitle = false;
    let skipNextText = false;
    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        if (token.type === 'heading_open' && token.tag === 'h1') {
            foundTitle = true;
            skipNextText = true;
        } else if (foundTitle) {
            if (skipNextText && token.type === 'inline') {
                skipNextText = false;
                continue;
            }
            content += token.content;
        }
    }
    return content;
}

export function extractPreview(markdownText: string, maxLength: number): string {
    const content = extractContent(markdownText);
    return content.substring(0, maxLength);
}