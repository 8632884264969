import React, { useCallback, useEffect, useState } from 'react';
import loadingSvg from '../assets/loading.svg';
import { AuthInfo } from './Backend';
export interface Loader {
    (authInfo: AuthInfo | undefined): Promise<any>;
}

export function withLoaders<P extends { authInfo?: AuthInfo }>(
    Component: React.ComponentType<P & { reload: () => void; loaderResults: { [key: string]: any } }>,
    loaders: { [key: string]: Loader }
): React.FC<Omit<P, 'reload' | 'loaderResults'>> {
    return (props: Omit<P, 'reload' | 'loaderResults'>) => {
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState<Error | null>(null);
        const [loaderResults, setLoaderResults] = useState<{ [key: string]: any }>({});

        const load = useCallback(() => {
            setLoading(true);
            setError(null);
            const promises = Object.entries(loaders).map(([key, loader]) =>
                loader(props.authInfo).then(result => [key, result])
            );
            Promise.all(promises)
                .then(results => {
                    const newLoaderResults = Object.fromEntries(results);
                    setLoaderResults(newLoaderResults);
                })
                .catch(setError)
                .finally(() => setLoading(false));
        }, [props.authInfo]);

        useEffect(() => {
            load();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.authInfo]);

        if (loading) {
            return <div style={{
                position: "relative",
                width: "100%",
                height: "100vh"
            }}>
                <img src={loadingSvg} alt="Loading..." style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }} />
            </div>;
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        }

        return <Component {...props as P} reload={load} loaderResults={loaderResults} />;
    };
}