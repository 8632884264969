import { Box } from '@mui/material';
import React, { useEffect, useState, } from 'react';
import { useParams } from 'react-router-dom';
import MarkDownEditor from '../components/MarkDownEditor/MarkDownEditor';

type Props = {};


const ViewMdPage: React.FC<Props> = () => {
    const [markDownText, setMarkDownText] = useState('');

    const [isFullScreen, setIsFullScreen] = useState(false);
    const { url } = useParams<{ url: string }>();

    useEffect(() => {
        if (!url) {
            return;
        }
        console.log('Loading url:', "/" + url);
        fetch("/" + url)
            .then(response => response.text())
            .then(text => setMarkDownText(text))
            .catch(error => console.error(`Error loading url ${url}`, error));
    }, [url]);
 
    return (
        <Box display="flex" flexWrap="wrap" justifyContent="center" flexDirection="row" padding={0} >
            <Box id="article-and-comment-box" display="flex" flexWrap="wrap" alignContent="flex-start" padding={1} flexGrow={1} flexBasis="66vw" >
                <Box width="100%" >
                    <MarkDownEditor
                        initialText={markDownText}
                        viewOnly={true}
                        showFullScreen={isFullScreen}
                        onShowFullScreenChange={setIsFullScreen}
                    />
                </Box>
            </Box>
        </Box >
    );
};

export default ViewMdPage;
