import { ExpandLess, ExpandMore, Publish, Save, Title } from '@mui/icons-material';
import { Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';
import { MdEditor } from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loadCategories } from '../common/loaders/categories';
import { Post, loadDraftOrPost, savePost } from '../common/loaders/postLoaders';
import { extractTitle } from '../common/markdownManipulation';
import { AuthInfo, BadRequestError } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';
import { withLoaders } from '../hocComponents/withLoaders';

type Props = {
    authInfo: AuthInfo;
    reload: () => void;
    loaderResults: { [key: string]: any };
};

const EditPostPage: React.FC<Props> = ({ authInfo, loaderResults }) => {
    const { selectedFeed } = useParams<{ selectedFeed: string }>();
    const { selectedPostId } = useParams<{ selectedPostId: string }>();
    const navigate = useNavigate(); // Get the navigate function
    const [postId, setPostId] = useState<number | null>(selectedPostId ? parseInt(selectedPostId) : null);
    const [postTitle, setPostTitle] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [categoryError, setCategoryError] = useState<boolean>(false);
    const [marDownTextError, setMarDownTextError] = useState<string | null>(null);

    const [savedState, setSavedState] = useState(true); // Add savedState state 
    const [markDownText, setMarkDownText] = useState('');

    const [isHeaderCollapsed, setIsHeaderCollapsed] = useState<boolean>(false);
    const [loadError, setLoadError] = useState<string | null>(null);

    // Load default post template
    useEffect(() => {
        if (!postId) {
            fetch('/default_post_template.md')
                .then(response => response.text())
                .then(text => handleEditorChange(text))
                .catch(error => console.error('Error loading default_post_template.md:', error));
        } else {
            // Load post from server
            console.log('Loading post with id:', postId);
            loadDraftOrPost(authInfo, postId.toString()).then((post) => {
                console.log('Post loaded:', post);
                setSelectedCategory(post.category);
                setMarkDownText(post.content);
                setPostTitle(extractTitle(post.content));
                setSavedState(true);
            }).catch((error) => {
                console.error('Error loading post:', error);
                setLoadError('Error loading post:' + error.message);
            });
        }
    }, [authInfo, postId]);

    const validate = useCallback((): boolean => {
        let valid = true;
        if (postTitle === '') {
            setMarDownTextError((_prev) => "You need to start with your post title in a H1 header (# Title)");
            valid = false;
        }
        if (selectedCategory === '') {
            setCategoryError(true);
            valid = false;
        }
        if (markDownText === '') {
            setMarDownTextError((_prev) => "Post content is required");
            valid = false;
        }
        if (!valid && isHeaderCollapsed) {
            setIsHeaderCollapsed(false)
        }
        return valid;
    }, [postTitle, selectedCategory, markDownText, isHeaderCollapsed]);

    const handleSave = useCallback((publish: boolean) => {
        if (!validate()) {
            return;
        }
        console.log('Saving draft...');
        const post = {
            id: postId,
            publish: publish,
            feed_name: selectedFeed,
            category: selectedCategory,
            content: markDownText
        } as Post;
        savePost(authInfo, post).then((savedPostId) => {
            console.log("Post saved with id:", savedPostId);
            setPostId(savedPostId);
            setMarDownTextError((_prev) => null);
            setSavedState(true);
            if (publish) {
                // Redirect to feed page
                navigate(`/feed/${selectedFeed}`);
            }
        }).catch((error) => {
            if (error instanceof BadRequestError) {
                console.error("Error saving post:", error.message);
                setMarDownTextError(error.message);
                return;
            }
            console.error("Error saving post:", error);
            setMarDownTextError("Error saving post.");
        });
    }, [authInfo, markDownText, navigate, postId, selectedCategory, selectedFeed, validate]);


    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        setSelectedCategory(event.target.value as string);
        setCategoryError(false);
        setSavedState(false);
    };

    function handleEditorChange(text: string) {
        setMarkDownText(text);
        setPostTitle(extractTitle(text));
        setSavedState(false);
    }


    const handleCollapse = () => {
        setIsHeaderCollapsed(!isHeaderCollapsed);
    };

    const onUploadImg = async (files: Array<File>, callback: (urls: string[] | { url: string; alt: string; title: string }[]) => void) => {
        const res = await Promise.all(
            files.map((file) => {
                console.log('Uploading file:', file);
                return null;
            })
        );

        // Approach 1
        // callback(res.map((item) => item.data.url));
        // Approach 2
        // callback(
        //   res.map((item: any) => ({
        //     url: item.data.url,
        //     alt: 'alt',
        //     title: 'title'
        //   }))
        // );
        return res;
    };
 

    if (loadError) {
        return <Box>{loadError}</Box>;
    }

    return (
        <Box height="100%" display="flex" flexDirection="column" alignItems="start" alignContent="stretch" sx={{ scrollBehavior: "auto" }}>
            <Box flexShrink={1} id="collapsible-box">
                <h2>
                    <IconButton onClick={handleCollapse}>
                        {isHeaderCollapsed ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                    {postId ? `Edit post ${postId}` : `Create post`}
                </h2>
                <Box padding={2} display={isHeaderCollapsed ? "none" : "block"}>
                    <TextField required disabled variant="standard"
                        label="Feed"
                        value={selectedFeed}
                        style={{ margin: 5 }}
                    />
                    <TextField required disabled variant="standard"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Title />
                                </InputAdornment>
                            ),
                        }}
                        label="Post Title" placeholder="Set a post title in the text..."
                        value={postTitle}
                        style={{ margin: 5 }}
                    />
                    <FormControl sx={{ minWidth: 120 }} style={{ margin: 5 }} >
                        <InputLabel shrink>Category *</InputLabel>
                        <Select required native variant="standard"
                            value={selectedCategory} onChange={handleCategoryChange}
                        >
                            <option value="" disabled>Select a category</option>
                            {loaderResults.categories.map((category: string) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </Select>
                        {categoryError && <FormHelperText error={categoryError}>Category is required</FormHelperText>}
                    </FormControl>
                </Box>
            </Box>
            <Box width="100%" padding={1} display="flex" flexDirection="column" alignContent="stretch" flexGrow={1} 
            marginBottom={1} >
                {marDownTextError && <FormHelperText error={true}>{marDownTextError}</FormHelperText>}
                <MdEditor
                    modelValue={markDownText}
                    onChange={handleEditorChange}
                    onSave={() => handleSave(false)}
                    onUploadImg={onUploadImg}
                    language="en-US"
                    autoDetectCode={true}
                    maxLength={10000}
                    scrollAuto={true}
                    style={{ flexGrow: 1, width: "100%", height: "auto" }}
                />
            </Box>
            <Box minHeight={50} display="flex" flexDirection="column" alignItems="end" alignContent="end">
                {/* Spacer for the bottom bar below */}
                <hr/>
            </Box>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10, p: 0.5 }} elevation={3} >
                <Box width="100%" display="flex" justifyContent="flex-end" height={50}>
                    <Button onClick={() => handleSave(false)} startIcon={<Save />} variant="outlined" style={{ margin: 2 }}>
                        {savedState ? "Saved" : "*Save "}
                    </Button>
                    <Button onClick={() => handleSave(true)} startIcon={<Publish />} variant="contained" color="primary" style={{ margin: 2 }}>
                        Publish
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default withAuth(
    withLoaders(EditPostPage, { categories: loadCategories })
);
