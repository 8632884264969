import { Avatar, Box, Tab, Tabs, Typography } from "@mui/material";
import { MdPreview } from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTimeAgo } from "../common/getTimeAgo";
import { FeedDetails, loadFeedDetails } from "../common/loaders/feedDetails";
import { loadFeedDrafts, loadFeedPosts } from "../common/loaders/postLoaders";
import { numSubscribers } from "../common/loaders/subscriptions";
import { isMobileViewport } from "../common/mobileUtils";
import PostCardBox from "../components/PostCardBox";
import SubscribeButton from "../components/SubscribeButton";
import { useAuthInfoContext } from "../hocComponents/AuthProvider";
import { AuthInfo } from "../hocComponents/Backend";
import { Loader, withLoaders } from "../hocComponents/withLoaders";
import { withOptionalAuth } from "../hocComponents/withOptionalAuth";

type Props = {
  authInfo: AuthInfo | undefined,
  reload: () => void;
  loaderResults: { [key: string]: any };
};

const FeedPage: React.FC<Props> = (props) => { 
  const authInfo = useAuthInfoContext();
  const feedDetails = props.loaderResults.feedDetails as FeedDetails;
  console.log("feedDetails", feedDetails)
  const [activeTab, setActiveTab] = React.useState("your-posts");
  const avatarWidth = 150;
  const avatarBoxWidth = avatarWidth + 50;

  const [isMobileMode, setIsMobileMode] = useState<boolean>(false);



  useEffect(() => {
    const handleResize = () => {
      setIsMobileMode(isMobileViewport());
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };

  return (
    <Box width="100%"
      sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}
    >
      <Box
        display="flex" flexWrap="wrap" width="100%"
        sx={isMobileMode ? {} : { maxHeight: '30vh', overflow: 'auto' }}
      >
        <Box sx={{ display: "flex", flexWrap: "nowrap", flexDirection: "column", m: 1, maxWidth: avatarBoxWidth }}>
          <Avatar
            src="/logo512.png"
            alt={"@" + feedDetails.name + " profile"}
            sx={{ width: avatarWidth, height: avatarWidth, mb: 2, mr: 2 }}
          >

          </Avatar>
          <Typography variant="h6" component="h6" sx={{ mb: 1, width: avatarBoxWidth }}>
            {feedDetails?.description}
          </Typography>
          <Box >
            <Typography variant="body2" sx={{ mr: 2 }}>
              Created {getTimeAgo(feedDetails?.created_at)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              @{feedDetails?.name}
            </Typography>
            <Typography variant="body2" sx={{ mr: 2 }}>
              {props.loaderResults.numSubscribers} subscribers
            </Typography>
          </Box>
          <SubscribeButton authInfo={authInfo} feedName={feedDetails?.name} />
        </Box>
        <Box
          sx={isMobileMode ? { width: "100%" } : { minWidth: avatarBoxWidth, maxWidth: `calc(100% - ${avatarBoxWidth + 50}px)` }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            <MdPreview
              modelValue={feedDetails?.about || ""}
              language="en-US"
            />
          </Typography>
        </Box>
      </Box>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ width: "90%", borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="Posts" value="your-posts" />
        <Tab label="Drafts" value="your-drafts" />
      </Tabs>

      {activeTab === "your-posts" && (
        <PostCardBox posts={props.loaderResults.posts} authInfo={authInfo} /> 
      )}

      {activeTab === "your-drafts" && (
        <PostCardBox posts={props.loaderResults.drafts} authInfo={authInfo} /> 
      )}
      <Box sx={{ height: 150 }}></Box>
    </Box>

  );

};

const FeedPageWithLoaders: React.FC<Omit<Props, 'authInfo' | 'reload' | 'loaderResults'>> = (props) => {
  const { feedName } = useParams<{ feedName: string }>();
  if (!feedName) {
    return <div>Feed name not provided</div>;
  }
  const loadFeedDetailsP: Loader = (authInfo: AuthInfo | undefined) => loadFeedDetails(authInfo, feedName);
  const loadFeedPostsP: Loader = (authInfo: AuthInfo | undefined) => loadFeedPosts(authInfo, feedName);
  const loadFeedDraftsP: Loader = (authInfo: AuthInfo | undefined) => loadFeedDrafts(authInfo, feedName);

  const FeedPageComponent = withOptionalAuth(withLoaders(FeedPage, {
    feedDetails: loadFeedDetailsP,
    posts: loadFeedPostsP,
    drafts: loadFeedDraftsP,
    numSubscribers: (authInfo: AuthInfo | undefined) => numSubscribers(authInfo, feedName),
  }));

  return <FeedPageComponent {...props} />;
}

export default FeedPageWithLoaders; 