
import React from 'react';
import { useParams } from 'react-router-dom';
import { loadCategories } from '../common/loaders/categories';
import { Post, loadHomePosts, searchPosts } from '../common/loaders/postLoaders';
import { CategoriesHeader } from '../components/CategoriesHeader';
import PostCardBox from '../components/PostCardBox';
import { AuthInfo } from '../hocComponents/Backend';
import { Loader, withLoaders } from '../hocComponents/withLoaders';
import { withOptionalAuth } from '../hocComponents/withOptionalAuth';
import { DrawerHeader } from '../styles/AppSideBarStyles';

interface Props {
    authInfo: AuthInfo | undefined;
    reload: () => void;
    loaderResults: { [key: string]: any };
}

const HomePage: React.FC<Props> = (props) => {
    const categoriesList = ['ALL', ...props.loaderResults.categories];
    const posts: Post[] = props.loaderResults.homePosts;
    return (
        <div  >
            <CategoriesHeader categories={categoriesList} />
            <DrawerHeader />

            <PostCardBox posts={posts} authInfo={props.authInfo} />
        </div>
    );
};



const HomePageWithLoaders: React.FC<Omit<Props, 'authInfo' | 'reload' | 'loaderResults'>> = (props) => {
    const { searchTerm } = useParams<{ searchTerm: string }>();

    const loadFeedDetailsP: Loader = (authInfo: AuthInfo | undefined) => (!searchTerm)
        ? loadHomePosts(authInfo)
        : searchPosts(authInfo, searchTerm)
        ;

    const HomePageComponent = withOptionalAuth(withLoaders(HomePage, {
        homePosts: loadFeedDetailsP,
        categories: loadCategories
    }));

    return <HomePageComponent {...props} />;
}

export default HomePageWithLoaders;
