import { ChatOutlined } from "@mui/icons-material";
import { Avatar, Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getGravatarUrl } from "../../common/gravatar";
import { formatNumComments } from "../../common/loaders/comments";
import { userIdFromAuthInfo } from "../../common/userIdFromEmail";
import { AuthInfo } from "../../hocComponents/Backend";


interface CommentListHeaderProps {
    authInfo: AuthInfo | undefined,
    postId: string;
    numComments: number;
    orderBy: `newest` | `oldest`;
    onChangeOrderBy?: (orderBy: `newest` | `oldest`) => void;
}

function CommentListHeader({ postId, authInfo, numComments, orderBy, onChangeOrderBy }: CommentListHeaderProps) {

    function handleChange(event: SelectChangeEvent<string>) {
        onChangeOrderBy && onChangeOrderBy(event.target.value as `newest` | `oldest`);
    }

    return (
        <Box width="100%" display="flex" alignItems="center" m={1}>
            <ChatOutlined sx={{ mr: 2 }} /> {formatNumComments(numComments)}
            <FormControl variant="standard" size="small" sx={{ ml: 5 }}>
                <Select label="Order By" value={orderBy} onChange={handleChange}>
                    <MenuItem key="newest" value="newest">Newest</MenuItem>
                    <MenuItem key="oldest" value="oldest">Oldest</MenuItem>
                </Select>
            </FormControl>
            <Box margin="auto" />
            {userIdFromAuthInfo(authInfo)}
            <Avatar src={getGravatarUrl(authInfo?.providerInfo.email || '')} sx={{ width: 32, height: 32, ml: 2, mr: 2 }} />
        </Box>
    )
}



export default CommentListHeader;