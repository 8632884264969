import { CancelOutlined, PostAddOutlined } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { PostComment, savePostComment } from "../../common/loaders/comments";
import { AuthInfo } from "../../hocComponents/Backend";


interface CommentEditorProps {
    authInfo: AuthInfo | undefined,
    postId: string;
    parentCommentId: string | null;
    commentId: string | null;
    hideCancel?: boolean;
    onCancel?: () => void;
    onSave?: (postComment: PostComment) => void;
    initialText?: string;
    buttonTitle?: string;
}

function CommentEditor({ postId, authInfo, parentCommentId, commentId, hideCancel, onCancel, onSave, initialText, buttonTitle }: CommentEditorProps) {
    const [text, setText] = useState<string>(initialText || '')
    const [error, setError] = useState<string | null>(null)
    const [saving, setSaving] = useState<boolean>(false)

    function handleClick() {
        if (!authInfo) {
            alert('Please login to post a comment');
            return;
        }
        const comment: PostComment = {
            post_id: parseInt(postId),
            author_email: authInfo?.providerInfo?.email || '',
            body: text,
            id: commentId,
            parent: parentCommentId,
            is_liked: false,
            likes_count: 0,
            replies_count: 0,
            created_at: new Date(),
        }
        setSaving(true);
        console.log('Saving comment', comment);
        savePostComment(authInfo!, comment)
            .then((savedComment) => {
                setText('');
                setError(null);                
                if (onSave) {
                    onSave(savedComment);
                }                
            }).catch((error) => {
                setError(error.message);
            }).finally(() => {
                setSaving(false);
            });
    }

    return (
        <Box width="100%" component="form" noValidate autoComplete="off"
            display="flex" flexDirection="column" alignItems="stretch"
        >
            <TextField
                fullWidth
                id="outlined-multiline-flexible"
                multiline
                placeholder="Write a comment.."
                minRows={4}
                margin="dense"
                InputProps={{
                    inputProps: {
                        style: {
                            resize: 'vertical',
                            overflow: 'auto'
                        },
                        maxLength: 500
                    }
                }}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Box display="flex" justifyContent="flex-end">
                {error && <Box color="red">{error}</Box>}
                {!hideCancel && <Button size="small" variant="contained" color="secondary"
                    onClick={onCancel} disabled={saving}
                >
                    Cancel <CancelOutlined sx={{ ml: 2 }} />
                </Button>}
                <div style={{ marginLeft: 15 }} />
                {authInfo ?
                    <Button size="small" variant="contained" color="primary" onClick={handleClick} disabled={saving}>
                        {buttonTitle || "Post comment"} <PostAddOutlined sx={{ ml: 2 }} />
                    </Button>
                    : <Button size="small" variant="contained" color="primary">
                        Login to post <PostAddOutlined sx={{ ml: 2 }} />
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default CommentEditor;