
import { AddCircle, Edit } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    Paper,
    Typography
} from "@mui/material";

import { MdEditor } from 'md-editor-rt';
import 'md-editor-rt/lib/style.css';
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Feed, checkWhetherFeedExists, loadFeeds, saveFeed } from "../common/loaders/userFeeds";

import { userIdFromAuthInfo } from "../common/userIdFromEmail";
import { AuthInfo, BadRequestError } from "../hocComponents/Backend";
import { withAuth } from "../hocComponents/withAuth";
import { withLoaders } from "../hocComponents/withLoaders";
type Props = {
    authInfo: AuthInfo;
    reload: () => void;
    loaderResults: { [key: string]: any };
};

export const validateFeed = async (
    authInfo: AuthInfo, feed: Feed, isCreating: boolean,
    user_feeds_len: number,
): Promise<string | null> => {
    const maxFeedsAllowed = 10;
    if (feed.name.length > 20 || feed.name.length < 3) {
        return `Feed name must be between 3 and 20 characters. [${feed.name.length}]`;
    }
    if (!/^[a-z0-9_]+$/.test(feed.name)) {
        return "Feed name can only contain alphanumeric characters and _"
    }
    if (feed.description.length > 50 || feed.description.length < 1) {
        return `Description field must be between 1 and 50 characters. [${feed.description.length}]`;
    }
    if (feed.about.length > 1000 || feed.about.length < 1) {
        return `About field must be between 1 and 1000 characters. [${feed.about.length}]`;
    }
    if (user_feeds_len >= maxFeedsAllowed) {
        return `Sorry, at this point you can only have up to ${maxFeedsAllowed} feeds. This might change in the future, but for now, you can only have ${maxFeedsAllowed} feeds.`;
    }
    console.log("Checking whether feed exists:", feed.name, isCreating, checkWhetherFeedExists)
    if (isCreating && await checkWhetherFeedExists(authInfo, feed.name)) {
        return "Feed with this name already exists.";
    }
    return null;
};

const ManageFeedsPage: React.FC<Props> = ({ authInfo, reload, loaderResults }) => {
    const [userFeeds, reloadFeeds] = [loaderResults.userFeeds, reload];

    const [openModal, setOpenModal] = React.useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [selectedFeed, setSelectedFeed] = React.useState<Feed>(createDefaultFeed());
    const [isCreating, setIsCreating] = React.useState(false);
    const [validationErrors, setValidationErrors] = React.useState<string | null>(null);


    const handleOpenModal = (feed: Feed) => {
        setSelectedFeed(feed);
        setValidationErrors(null);
        console.log("Opening modal for feed:", feed);
        setIsCreating(feed.id === -1);
        setOpenModal(true);
    };

    const handleCloseModal = (event: object, reason: string) => {
        if (reason !== 'backdropClick') {
            setOpenModal(false);
        }
    };
    const handleSaveFeed = async () => {
        setOpenConfirmationModal(false)
        console.log("Saving feed:", selectedFeed);
        const validationError = await validateFeed(
            authInfo, selectedFeed, isCreating, userFeeds.length,
        );
        if (validationError) {
            return;
        }

        saveFeed(authInfo, selectedFeed).then(() => {
            console.log("Saved!");
            reloadFeeds();
            setOpenModal(false);
        }).catch((error) => {
            if (error instanceof BadRequestError) {
                console.error("Error saving feed:", error.message);
                setValidationErrors(error.message);
                return;
            }
            console.error("Error saving feed:", error);
            setValidationErrors("Error saving feed.");
        });
    };

    const fetchData = useCallback(async () => {
        const validationError = await validateFeed(
            authInfo, selectedFeed, isCreating, userFeeds.length
        );
        setValidationErrors(validationError);
    }, [authInfo, isCreating, selectedFeed, userFeeds.length]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function createDefaultFeed(): Feed {
        return {
            id: -1,
            created_at: "",
            updated_at: "",
            name: userIdFromAuthInfo(authInfo),
            active: true,
            description: "",
            about: "",
        };
    }

    function renderConfirmationModal() {
        return <Dialog open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {isCreating ? "create" : "modify"} the feed named @{selectedFeed.name}?
                    {!isCreating && "The state of the feed " + (selectedFeed.active ? "inactive" : "active") + "."}
                    This operation cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenConfirmationModal(false)}>Cancel</Button>
                <Button onClick={handleSaveFeed}>Yes, {isCreating ? "create it" : "save it"}</Button>
            </DialogActions>
        </Dialog>
    }

    function renderEditModal() {

        return (
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="lg" >
                <Box sx={{ p: 2 }}  >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {isCreating ? "Create a new feed" : "Edit feed"}
                    </Typography>
                    {!isCreating &&
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel htmlFor="feed-active">Active</InputLabel>
                            <Checkbox
                                id="feed-active"
                                checked={selectedFeed.active}
                                onChange={(e) => {
                                    setSelectedFeed((prevFeed: Feed) => ({
                                        ...prevFeed,
                                        active: e.target.checked,
                                    }));
                                }}
                            />

                            <FormHelperText>
                                If a feed is not active, no one will be able to see any of its posts
                            </FormHelperText>
                        </FormControl>
                    }
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel htmlFor="feed-name">Feed Name - this will be your unique field name. E.g.: johndoe </InputLabel>
                        <Input
                            id="feed-name"
                            value={selectedFeed.name}
                            disabled={!isCreating}
                            onChange={(e) => {
                                setSelectedFeed((prevFeed: Feed) => ({
                                    ...prevFeed,
                                    name: e.target.value,
                                }));
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel htmlFor="feed-description">Description - write here a short description for your feed. E. g.: "Joe's feed"</InputLabel>
                        <Input
                            id="feed-description"
                            value={selectedFeed.description}
                            onChange={(e) => {
                                setSelectedFeed((prevFeed: Feed) => ({
                                    ...prevFeed,
                                    description: e.target.value,
                                }));
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <FormHelperText>
                            About your feed - add a detailed description in here. E. g:
                            "This is Joe's feed. I will post about my daily life, my hobbies, and my thoughts."
                        </FormHelperText>
                        <MdEditor
                            language="en-US"
                            editorId="feed-about"
                            style={{ minHeight: "300px" }}
                            modelValue={selectedFeed.about}
                            onChange={(text: string) => {
                                setSelectedFeed((prevFeed: Feed) => ({
                                    ...prevFeed,
                                    about: text,
                                }));
                            }}
                            toolbars={[
                                'pageFullscreen',
                                'fullscreen',
                                'preview',
                                'htmlPreview',
                                '=',
                                '-',
                                'bold',
                                'underline',
                                'italic',
                                '-',
                                'strikeThrough',
                                'title',
                                'sub',
                                'sup',
                                'quote',
                                'unorderedList',
                                'orderedList',
                                'task', // ^2.4.0
                                '-',
                                'codeRow',
                                'code',
                                'link',
                                'table',
                                'mermaid',
                                'katex',
                                '-',
                                'revoke',
                                'next',
                                'save',
                            ]}
                        />
                    </FormControl>

                    <Box sx={{ mt: 2 }}>
                        {validationErrors && (
                            <Typography variant="body1" color="error">
                                {validationErrors}
                            </Typography>
                        )}
                    </Box>

                    <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={() => setOpenConfirmationModal(true)} disabled={validationErrors !== null}>
                        Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => handleCloseModal({}, "cancel")}>
                        Cancel
                    </Button>
                </Box>
                {renderConfirmationModal()}
            </Dialog>
        );
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={6}>
                <Box style={{ padding: 20, marginTop: 20, }} minWidth={500}>
                    <div>

                        {/* Feeds Paper */}
                        <Paper style={{ padding: 20, marginTop: 20 }}>
                            <Typography variant="h4"  >
                                Your Feeds
                            </Typography>

                            <Typography variant="body1" sx={{ mt: 3, mb: 5 }}>
                                These are your feeds. You can create, edit, and delete feeds here.

                                A feed is a collection of posts that you can share with others and
                                a feed name must be unique.
                            </Typography>
                            <hr />

                            {/* Feeds Grid */}
                            <Grid container style={{ marginTop: 15 }}>
                                {(userFeeds.length > 0) && (
                                    /* Grid Header */
                                    <Grid container alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Feed Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Active
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Created At
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Updated At
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                                Actions
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {/* Feeds */}
                                {userFeeds.map((feed: Feed) => (
                                    <Grid container alignItems="center" key={feed.name}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                <Link to={`/feed/${feed.name}`}>@{feed.name}</Link>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant="body1"  >
                                                {feed.active ? "Yes" : "No"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                {feed.created_at}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body2">
                                                {feed.updated_at}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {/* Edit Feed Button */}
                                            <IconButton onClick={() => handleOpenModal(feed)}>
                                                <Edit />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>

                        {/* Add Feed Button */}
                        <Button variant="contained" color="primary" sx={{ mt: 5 }} onClick={() => handleOpenModal(createDefaultFeed())}>
                            <AddCircle sx={{ mr: 1 }} /> Create a new Feed
                        </Button>
                        {renderEditModal()}
                    </div>
                </Box>
            </Grid>
        </Grid>
    );
};

export const UnwrappedManageFeedsPage = ManageFeedsPage;
export default withAuth(withLoaders(ManageFeedsPage, { userFeeds: loadFeeds }));