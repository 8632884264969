import React, { useRef } from 'react';
import { Post } from '../common/loaders/postLoaders';

import { Box } from '@mui/material';
import { AuthInfo } from '../hocComponents/Backend';
import { useCardWidth } from '../hooks/postCardWidth';
import PostCard from './PostCard';
interface Props {
    authInfo: AuthInfo | undefined;
    posts: Post[]
    align?: 'left' | 'center' ;
}

const PostCardBox: React.FC<Props> = ({ posts, authInfo, align = 'left' }) => {
    const cardMarginPx = 4;
    const containerRef = useRef(null);
    const cardWidth = useCardWidth(containerRef, cardMarginPx);
    return <Box
        ref={containerRef}
        display="flex" flexWrap="wrap" justifyContent={align === "center" ? "center" : "flex-start"}  alignItems="stretch"
        sx={{ p: 0, m: 0, }}
    >
        {posts.length === 0 && <Box>No posts found</Box>}
        {
            posts.map((post, i) => {
                return <PostCard
                    width={cardWidth}
                    margin={`${cardMarginPx}px`}
                    authInfo={authInfo}
                    key={i}
                    post={post}
                />
            })
        }
    </Box>
}


export default PostCardBox;