import { Box } from '@mui/material';
import markdownit from 'markdown-it';
import React, { useEffect, useRef } from 'react';
import Editor, { Plugins, default as ReactMdEditor } from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import CustomFullScreen from './CustomFullScreen';
import './customEditor.css';


type Props = {
    initialText: string,
    onChange?: (html: string, text: string) => void,
    viewOnly: boolean,
    showFullScreen: boolean,
    onShowFullScreenChange: (enable: boolean) => void,
};
// TODO switch to https://www.npmjs.com/package/md-editor-rt

const MarkDownEditor: React.FC<Props> = ({ initialText, onChange, viewOnly, showFullScreen, onShowFullScreenChange }) => {
    const mdEditorRef = useRef<ReactMdEditor>(null); // Create a ref using useRef

    useEffect(() => {
        // Use our custom full screen plugin, as it removes the menu when leaving full screen
        Editor.unuse(Plugins.FullScreen);
        Editor.use(CustomFullScreen, { onFullScreenChange: onShowFullScreenChange, changeMenuVisibility: viewOnly });
    }, [viewOnly, onShowFullScreenChange])

    useEffect(() => { 
        if (mdEditorRef.current) {
            mdEditorRef.current.fullScreen(showFullScreen); 
        }
    }, [showFullScreen]);

    const mdParser = new markdownit(
        /* Markdown-it options */
        { html: false }
    );

    function handleEditorChange({ html, text }: { html: string, text: string }) {
        if (onChange) {
            onChange(html, text);
        }
    }

    return (
        (!viewOnly)
            ? (
                <ReactMdEditor
                    ref={mdEditorRef} // Assign the ref to MdEditor
                    plugins={['header', 'font-bold', 'font-italic', 'font-underline', 'font-strikethrough', 'list-unordered', 'list-ordered',
                        'block-quote', 'block-wrap', 'block-code-inline', 'block-code-block', 'table', 'image', 'link', 'clear', 'logger',
                        'mode-toggle', 'custom-full-screen', 'tab-insert', 'undo', 'redo', 'save'
                    ]}
                    style={{width: '100%', minHeight: '100%',textAlign: "left", zIndex: showFullScreen ? 2000 : 0 }}                    
                    renderHTML={text => mdParser.render(text)}
                    onChange={handleEditorChange}
                    value={initialText}
                />
            ) : (
                <Box width="100%" height="100%">
                    <ReactMdEditor
                        ref={mdEditorRef} // Assign the ref to MdEditor
                        plugins={['custom-full-screen']}
                        style={{ width: '100%', height: '100%', textAlign: "left", zIndex: showFullScreen ? 2000 : 0 }}
                        renderHTML={text => mdParser.render(text)}
                        view={{ menu: false, md: false, html: true }}
                        readOnly={true}
                        onChange={handleEditorChange}
                        value={initialText}
                    />
                </Box>
            )
    );
};

export default MarkDownEditor;
