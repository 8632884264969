import { AuthInfo, call_backend, call_backend_post } from "../../hocComponents/Backend";

export function likePost(authInfo: AuthInfo| undefined, postId: string) {
    return call_backend_post(authInfo, `secure/like_post/${postId}`, {}, true);
}
export function unlikePost(authInfo: AuthInfo| undefined, postId: string) {
    return call_backend_post(authInfo, `secure/unlike_post/${postId}`, {}, true);
}
export function dislikePost(authInfo: AuthInfo| undefined, postId: string) {
    return call_backend_post(authInfo, `secure/dislike_post/${postId}`, {}, true);
}
export function undislikePost(authInfo: AuthInfo| undefined, postId: string) {
    return call_backend_post(authInfo, `secure/undislike_post/${postId}`, {}, true);
}

export function formatNumLikes(numLikes: number): string {
    if (numLikes === 0) {
        return '0';
    }
    const abbreviations = ['', 'k', 'M', 'B', 'T'];
    const index = Math.floor(Math.log10(numLikes) / 3);
    const formattedNum = (numLikes / Math.pow(10, index * 3)).toFixed(0);
    return formattedNum + abbreviations[index];
}

export async function numLikes(authInfo: AuthInfo | undefined, postId: string): Promise<number> {
    const response = await call_backend(authInfo, `public/num_likes/${postId}`); 
    const data = await response.json(); 
    return data.num_likes;
}

export async function numDislikes(authInfo: AuthInfo | undefined, postId: string): Promise<number> {
    const response = await call_backend(authInfo, `public/num_dislikes/${postId}`); 
    const data = await response.json(); 
    return data.num_dislikes;
}


export async function hasPostLike(authInfo: AuthInfo | undefined, postId: string): Promise<boolean> {
    if (!authInfo) {
        return false;
    }
    const response = await call_backend(authInfo, `secure/has_post_like/${postId}`,  true);
    const data = await response.json();
    return data.has_post_like;
}

export async function hasPostDislike(authInfo: AuthInfo | undefined, postId: string): Promise<boolean> {
    if (!authInfo) {
        return false;
    }
    const response = await call_backend(authInfo, `secure/has_post_dislike/${postId}`,  true);
    const data = await response.json();
    return data.has_post_dislike;
}