import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

const componentEnabled = false;
interface CategoriesHeaderProps {
  categories: string[];
}

export function CategoriesHeader({ categories }: CategoriesHeaderProps) {
  const [activeCategory, setActiveCategory] = useState('ALL');

  const handleCategoryChange = (category: string) => {
    setActiveCategory(category);
  };

  return ((!componentEnabled) ? <div /> :
    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }} position={"fixed"}
      bgcolor={"background.paper"} 
      alignItems={"left"} justifyContent={"left"} display={"flex"}  flexGrow={0} flexShrink={1}
      zIndex={1000} // TODO - this is a hack to get the header to show up on top of the cards
    >
      <Tabs value={activeCategory} sx={{ width: '85%' }}
        onChange={(_, newValue) => handleCategoryChange(newValue)}
        variant="scrollable" 
        scrollButtons={true}
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {categories.map((category) => (
          <Tab key={category} label={category} value={category} />
        ))}
      </Tabs>
    </Box>
  );
}
