
import { createBrowserRouter } from "react-router-dom";
import EditPostPage from "../pages/EditPostPage";
import ErrorPage from "../pages/ErrorPage";
import FeedPage from "../pages/FeedPage";
import HomePage from "../pages/HomePage";
import LogoutPage from "../pages/LogoutPage";
import ManageFeedsPage from "../pages/ManageFeedsPage";
import SelectFeedPage from "../pages/SelectFeedPage";
import SubscriptionsPage from "../pages/SubscriptionsPage";
import UserHistoryPage from "../pages/UserHistoryPage";
import UserProfilePage from "../pages/UserProfilePage";
import UserSettingsPage from "../pages/UserSettingsPage";
import ViewMd from "../pages/ViewMdPage";
import ViewPost from "../pages/ViewPostPage";
import CreateRedirector from "./CreateRedirector";
import CurrentFeedRedirector from "./CurrentFeedRedirector";


export const router = createBrowserRouter([
  { path: "/", element: <HomePage />, errorElement: <ErrorPage />, },
  { path: "/logout", element: <LogoutPage />, },
  { path: "/user-profile", element: <UserProfilePage />, },
  { path: "/create", element: <CreateRedirector />, },
  { path: "/current-feed", element: <CurrentFeedRedirector />, },
  { path: "/select-feed/:nextPage", element: <SelectFeedPage />, },
  { path: "/create-post/:selectedFeed", element: <EditPostPage />, },
  { path: "/edit-post/:selectedFeed/:selectedPostId", element: <EditPostPage />, },
  { path: "/settings", element: <UserSettingsPage />, },
  { path: "/history", element: <UserHistoryPage />, },
  { path: "/subscriptions", element: <SubscriptionsPage />, },
  { path: "/feed/:feedName", element: <FeedPage />, },
  { path: "/manage-feeds", element: <ManageFeedsPage />, },
  { path: "/post/:feed/:postId", element: <ViewPost />, },
  { path: "/view-md/:url", element: <ViewMd />, },
  { path: "/search/:searchTerm", element: <HomePage />, }, 
  { path: "/search", element: <HomePage />, },
]);
