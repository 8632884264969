import LoginIcon from '@mui/icons-material/AccountCircle';
import { Box, Button } from '@mui/material';
import { signInAction, useAuthInfoContext } from './AuthProvider';
import { AuthInfo } from './Backend';

export function withAuth<P extends object>(Component: React.ComponentType<P & { authInfo: AuthInfo | undefined }>): React.FC<Omit<P, 'authInfo'>> {
    return (props: Omit<P, 'authInfo'>) => {
        const authInfo = useAuthInfoContext();

        if (!authInfo) {
            return (
                <Box display="flex" justifyContent="center" width="100%" marginTop="10px" flexDirection="column">
                    <Box>
                        You are not logged in. Please log in to continue.
                    </Box>
                    <br />
                    <Button variant="contained" color="primary" startIcon={<LoginIcon />} onClick={signInAction}>
                        Log in
                    </Button>
                </Box>
            );
        }

        return <Component {...props as P} authInfo={authInfo} />;
    };
}