import { AuthInfo, call_backend } from "../../hocComponents/Backend";

export interface FeedDetails {
  name?: string;
  description?: string;
  about?: string;
  creator_email?: string;
  created_at?: Date;
  updated_at?: Date;
}

export async function loadFeedDetails(
  authInfo: AuthInfo | undefined,
  feedName: string,
): Promise<FeedDetails> {
  const response = await call_backend(authInfo, `public/feed_details/${feedName}`);
  const data = await response.json();
  
  data.created_at = new Date(data.created_at);
  data.updated_at = new Date(data.updated_at);
  
  return data;
}