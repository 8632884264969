import { ResourcesConfig } from '@aws-amplify/core';

const aws_config: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
            userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
            signUpVerificationMethod: 'code',
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_OAUTH_DOMAIN || '',
                    scopes: ['email', 'openid'],
                    redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGNIN || ''],
                    redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGNOUT || ''],
                    responseType: 'code',
                },
            },
        },
    },
};

export default aws_config;