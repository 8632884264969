import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from '@mui/material';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';
import AuthenticatedUser from './AuthenticatedUser';

interface AppTopBarProps {
  handleToggleSidebar: () => void;
}

const AppBar = styled(MuiAppBar, {})<AppBarProps>(
  ({ theme, }) => ({
    zIndex: theme.zIndex.drawer + 1,
  })
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100ch', 
    },
  },
}));


export function AppBarTop({ handleToggleSidebar }: AppTopBarProps) {

  const path = window.location.pathname.split('/');
  const initialSearchTerm = path.includes('search') ? decodeURIComponent(path[path.indexOf('search') + 1]) : '';
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm); 

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    window.location.href = `/search/${searchTerm}`;
  }

  return (
    <Box sx={{ flexGrow: 1, }}>
      <AppBar position="fixed"  >
        <Toolbar  >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, }}
            onClick={handleToggleSidebar}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexShrink: 1 }} />

          <Link href={"/"}>
            <img src="/logo192_transpbg.png" alt="logo" style={{ width: "40px", height: "40px", }} />
          </Link>
          <Search sx={{ flexGrow: 1 }} >
            <SearchIconWrapper >
              <SearchIcon />
            </SearchIconWrapper>
            <form onSubmit={handleSearchSubmit}> 
              <StyledInputBase
                value={searchTerm} onChange={handleSearchChange}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                
              />
            </form>
          </Search>
          <Box sx={{ flexShrink: 1 }} />

          <AuthenticatedUser />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

