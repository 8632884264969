import { Feed, Home, Subscriptions } from "@mui/icons-material";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Drawer } from "../styles/AppSideBarStyles";

export const sideBarItems = [
  { title: "Home", icon: <Home />, href: "/" },
  { title: "Subscriptions", icon: <Subscriptions />, href: "/subscriptions" },
];
export const sideBarItemsWhenOpenOnly = [
  { title: "Your feed", icon: <Feed />, href: "/current-feed" },
  // { title: "History", icon: <History />, href: "/history" },
];
interface AppSideBarProps {
  open: boolean;
}

export function AppSideBar({ open }: AppSideBarProps) {
  return (
    <>
      <Drawer variant="permanent" open={open}>
        <Toolbar />
        <Box>
          <List>
            {sideBarItems.map((item, _index) => (
              <ListItem key={item.title} disablePadding>
                <ListItemButton component={Link} href={item.href}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {open && (
            <>
              <Divider />
              <List>
                <ListItem key="you" disablePadding>
                  <ListItemButton component={Link} href="/user-profile">
                    <ListItemText primary={"You >"} />
                  </ListItemButton>
                </ListItem>
                {sideBarItemsWhenOpenOnly.map((item, _index) => (
                  <ListItem key={item.title} disablePadding>
                    <ListItemButton component={Link} href={item.href}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}
