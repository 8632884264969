
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { Amplify } from "aws-amplify";
import { RouterProvider } from "react-router-dom";
import awsConfig from '../aws-exports';
import { isMobileViewport } from '../common/mobileUtils';
import { router } from '../router/router';
import { DrawerHeader } from '../styles/AppSideBarStyles';
import { AppBottomMenu } from './AppBottomMenu';
import { AppSideBar } from './AppSideBar';
import { AppBarTop } from './AppTopBar';
Amplify.configure(awsConfig);

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleToggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const [isMobileMode, setIsMobileMode] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileMode(isMobileViewport());
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Box display={'flex'} alignContent={'flex-start'} alignItems={'flex-start'} textAlign={'left'} alignSelf={'left'} height= "90vh">
        <AppBarTop handleToggleSidebar={handleToggleSidebar} />

        {!isMobileMode && <AppSideBar open={sidebarOpen} />}
        <Box component="main" style={{ width: "100%", height: "100%" }}>
          <DrawerHeader /> 
          <RouterProvider router={router} />
        </Box>
        {isMobileMode && !("/post" in router.state.location) && <AppBottomMenu visible={!sidebarOpen} />}
      </Box>
    </>
  );
}

export default App;
