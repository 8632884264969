import { useEffect, useState } from 'react';

export const useCardWidth = (containerRef: React.RefObject<HTMLElement>, cardMarginPx = 4) => {
    const [cardWidth, setCardWidth] = useState('250px');

    useEffect(() => {
        const calculateCardWidth = () => {
            const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
            const minCardWidth = 250;
            const maxCardWidth = 400;

            // Calculate the maximum number of cards that can fit in the container width
            const maxCards = Math.floor(containerWidth / (minCardWidth + cardMarginPx * 2));

            // Calculate the actual card width
            const cardWidth = Math.min((containerWidth- (cardMarginPx * 2 * maxCards) ) / maxCards, maxCardWidth) - 1;

            //console.log('containerWidth', containerWidth, 'cardWidth', cardWidth, 'maxCards', maxCards, 'margin', `${cardMarginPx}px`);
            setCardWidth(`${cardWidth}px`);
        };

        calculateCardWidth();
        window.addEventListener('resize', calculateCardWidth);

        return () => {
            window.removeEventListener('resize', calculateCardWidth);
        };
    }, [containerRef, cardMarginPx]);

    return cardWidth;
};