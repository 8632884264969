import { Box } from '@mui/material';
import React from 'react';
import { Post, loadUserSubscriptions } from '../common/loaders/postLoaders';
import PostCardBox from '../components/PostCardBox';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';
import { withLoaders } from '../hocComponents/withLoaders';

type Props = {
    authInfo: AuthInfo;
    reload: () => void;
    loaderResults: { [key: string]: any };
};

const SubscriptionsPage: React.FC<Props> = (props) => { 
    const posts: Post[] = props.loaderResults.subscriptions;
    return <Box padding={2}>
        <h2>Posts from your subscriptions</h2>
        {posts.length === 0 && <Box>No posts found</Box>}
        <PostCardBox posts={posts} authInfo={props.authInfo} /> 
    </Box>
        ;
};

export default withAuth(withLoaders(SubscriptionsPage, {
    subscriptions: loadUserSubscriptions
}));