import { AuthInfo, call_backend, call_backend_post } from "../../hocComponents/Backend";

export interface Feed {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    active: boolean;
    description: string;
    about: string;
}
export async function loadFeeds(authInfo: AuthInfo | undefined): Promise<Feed[]> {
    if (!authInfo) {
        return [];
    }
    const response = await call_backend(authInfo, 'secure/user_feeds');
    return response.json();
}


export async function checkWhetherFeedExists(authInfo: AuthInfo, feedName: string): Promise<boolean> {
    try {
        const response = await call_backend(authInfo, `secure/feed_exists/${feedName}`);
        console.log('response', response);
        return response.status === 200; // 200 means the feed exists
    } catch (e) {
        return false;
    }    
}


export async function saveFeed(authInfo: AuthInfo, feed: Feed): Promise<void> {
    await call_backend_post(authInfo, 'secure/save_feed', {
        id: feed.id,
        name: feed.name,
        active: feed.active,
        description: feed.description,
        about: feed.about
    });
}