import { Edit, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, Reply, ThumbUpAlt, ThumbUpAltOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { getTimeAgo } from "../../common/getTimeAgo";
import { getGravatarUrl } from "../../common/gravatar";
import { PostComment, likePostComment, unlikePostComment } from "../../common/loaders/comments";
import { userIdFromEmail } from "../../common/userIdFromEmail";
import { AuthInfo } from "../../hocComponents/Backend";
import CommentEditor from "./CommentEditor";
import CommentList from "./CommentList";


interface CommentCardProps {
    authInfo: AuthInfo | undefined,
    postId: string;
    comment: PostComment;
    ident: boolean;
    onEdit: (postComment: PostComment) => void;
}

function CommentCardHeader({ postId, authInfo, comment, ident, onEdit }: CommentCardProps) {
    const childrenCommentsListRef = useRef<any>();
    const isOwner = comment.author_email === authInfo?.providerInfo.email;
    const [showReplies, setShowReplies] = useState(false);
    const [isLiked, setIsLiked] = useState(comment.is_liked);
    const [likesCount, setLikesCount] = useState(comment.likes_count);
    const [replying, setReplying] = useState(false);
    const [editing, setEditing] = useState(false);
    const [liking, setLiking] = useState(false);
    const [numReplies, setNumReplies] = useState(comment.replies_count);

    function handleAddReply(postComment: PostComment): void {
        childrenCommentsListRef.current?.addComment(postComment);
        setNumReplies((prev) => prev + 1);
    }

    function handleLikeDislike() {
        if (!authInfo) {
            alert('Please login to like a comment');
            return;
        }
        if (!comment.id) {
            console.error('Invalid comment id');
            return;
        }
        setLiking(true);

        const like_backend_call = isLiked ? unlikePostComment : likePostComment;
        const invertedIsLiked = !isLiked;
        const newLikeCount = likesCount + (invertedIsLiked ? 1 : -1);
        like_backend_call(authInfo, postId, comment.id)
            .then(() => {
                setIsLiked(invertedIsLiked)
                setLikesCount(newLikeCount);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLiking(false);
            })
    }

    return (
        <Box width="100%" display="flex" flexDirection="row" >
            {ident &&
                <Box display="flex" alignItems="center">
                    <Divider orientation="vertical" flexItem />
                    <Box width={10} />
                </Box>
            }
            <Box display="flex" flexDirection="column" flexGrow={1} >
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Avatar src={getGravatarUrl(comment.author_email)} sx={{ width: 20, height: 20, mr: 2 }} />
                    <Typography variant="subtitle2">
                        {userIdFromEmail(comment.author_email)} {isOwner && "(you)"}
                    </Typography>
                    <Box marginLeft={5} />
                    <Typography variant="caption">
                        {getTimeAgo(comment.created_at)}
                    </Typography>
                    <Box margin="auto" />
                </Box>
                <Typography variant="body1" m={1}>

                    {comment.body}
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <IconButton aria-label="like" onClick={handleLikeDislike} disabled={liking}>
                        {isLiked ? <ThumbUpAlt /> : <ThumbUpAltOutlined />}
                    </IconButton>
                    <Typography variant="caption">
                        {likesCount} likes
                    </Typography>
                    <Box marginRight={10} />
                    {!editing &&
                        <Button size="small" variant="text" startIcon={<Reply />} onClick={() => setReplying(true)}>
                            Reply
                        </Button>
                    }
                    {isOwner && !replying &&
                        <Button size="small" variant="text" startIcon={<Edit />} onClick={() => setEditing(true)}>
                            Edit
                        </Button>
                    }
                    <Box margin="auto" />
                </Box>
                <Box marginBottom={2} marginLeft={1}>
                    {numReplies > 0 &&
                        <Button size="small" variant="text" startIcon={showReplies ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                            onClick={() => setShowReplies(!showReplies)}
                        >
                            {showReplies ? "Hide" : "Show"} {numReplies} replies
                        </Button>
                    }
                    {replying &&
                        <Box display="flex" flexDirection="row" width="100%">
                            <CommentEditor
                                authInfo={authInfo} postId={postId}
                                parentCommentId={comment.id} commentId={null}
                                onCancel={() => setReplying(false)}
                                buttonTitle="Post reply"
                                onSave={(postComment) => {
                                    handleAddReply(postComment);
                                    setReplying(false);
                                    setShowReplies(true);
                                }}
                            />
                        </Box>
                    }
                    {editing &&
                        <Box display="flex" flexDirection="row" width="100%">
                            <CommentEditor
                                authInfo={authInfo} postId={postId}
                                parentCommentId={comment.parent} commentId={comment.id}
                                onCancel={() => setEditing(false)}
                                buttonTitle="Save changes" initialText={comment.body}
                                onSave={(postComment) => {
                                    onEdit(postComment);
                                    setEditing(false);
                                }}
                            />
                        </Box>
                    }
                    {showReplies &&
                        <Box display="flex" flexDirection="column" width="100%">
                            <CommentList ref={childrenCommentsListRef}
                                authInfo={authInfo} postId={postId} parentCommentId={comment.id} ident={true} />
                        </Box>
                    }
                </Box>

            </Box>
        </Box>
    )
}



export default CommentCardHeader;