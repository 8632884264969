import { Link } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
type Props = {
    // Define your component's props here
};

const LogoutPage: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        console.log("countdown: ", countdown)
        if (countdown <= 0) {
            console.log("redirecting")
            navigate("/");
        }
    }, [countdown, navigate]);

    return (
        <div style={{ textAlign: "center" }}>
            <p>You have been logged out</p>
            <p>Redirecting in {countdown} seconds...</p>
            <p>
                Click <Link href="/">here</Link> to go back to the home page
            </p>
        </div>
    );
};

export default LogoutPage;