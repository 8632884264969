import React from 'react';
import { AuthInfo } from '../hocComponents/Backend';
import { withAuth } from '../hocComponents/withAuth';

type Props = {
    authInfo: AuthInfo;
};
 
const UserHistoryPage: React.FC<Props> = (props) => { 

    return ( 
        <div style={{textAlign: "center"}}>
            UserHistoryPage TODO This page is under construction
        </div>
    );
};

export default withAuth(UserHistoryPage);